const DefaultUnifiedFieldId = {
  id: -1,
  name: -2,
  // startDate: -4,
  // endDate: -5,
  description: -5,
  avancement: -7,
  duration: -10,
  quantity: -11,
  quantityUnit: -12,
  yield: -13,
  order: -15,
  calendarId: -21,
  jobId: -22,
  activityState: -23,
}
// if combo => change value to array type
export default DefaultUnifiedFieldId;
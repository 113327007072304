import React from 'react';
import { Col } from 'antd';
import '../../assets/styles/wallet/Footer.css';

const WalletFooter = () => (
    <footer className="h-10 wallet-footer justify-center items-center">
        <Col span={12}>
            Gantt Manager ©{new Date().getFullYear()} &nbsp; Created by{' '}
            <a href="https://www.projetlineaire.com" target="_blank" rel="noreferrer">
                Projet Linéaire
            </a>
        </Col>
    </footer>
);

export default WalletFooter;

export const EXPLORER_ITEM_TYPE = Object.freeze({
    FOLDER: 'folder',
    PLANNING: 'planning',
  })

export const EXPLORER_VIEW_TYPE = Object.freeze({
    GRID: 'grid',
    LIST: 'list',
  })
  export const EXPLORER_VIEW_MODE = (i18n) => Object.freeze({
    LIVE: {label: i18n.t("baseline_explorer_modal.live"), value: 'live'},
    ARCHIVE: {label: i18n.t("baseline_explorer_modal.archives"), value: 'archive'},
    SANDBOX: {label: i18n.t("baseline_explorer_modal.sandbox"), value: 'sandbox'},
  })

export default {EXPLORER_ITEM_TYPE, EXPLORER_VIEW_TYPE}
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select, Space, Table, Popconfirm } from 'antd';
import WalletService from '../../../services/wallet.service';
import { WalletContext } from '../../../contexts/wallet/WalletContext';
import { notificationError, requestError } from '../../../helpers/notification';
import UserService from '../../../services/user.service';

const { Option } = Select;
const { Column } = Table;

const UsersTab = () => {
    const { selectedProgram } = useContext(WalletContext);
    const [usersToSelect, setUsersToSelect] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [loadingUserTable, setLoadingUserTable] = useState(false);
    const [programUsers, setProgramUsers] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            try {
                setLoadingUserTable(true);
                // users list
                const users = await WalletService.listUsers();
                const usersProgram = await UserService.listUsersByURL(selectedProgram.url);
                setProgramUsers(usersProgram);
                const usersProgramIds = usersProgram.map((i) => i.userId);
                // filter addable users with users in program
                setUsersToSelect(users.filter((i) => !usersProgramIds.includes(i.userId)));
                setLoadingUserTable(false);
            } catch (error) {
                setLoadingUserTable(false);
                setUsersToSelect([]);
                requestError(error, t('error_message.retrieving_users_error'));
            }
        })();
    }, []);

    const addUserToProgram = async () => {
        if (selectedUser) {
            try {
                
                await WalletService.addUserToProgram(selectedProgram.id, { userId: selectedUser })
                await WalletService.initUserJobToProgram(selectedProgram.url, selectedUser);
            } catch (error) {
                requestError(error, t('notification.subscribe_to_program'));
                return;
            }
            // compute table
            setProgramUsers([...programUsers, { ...usersToSelect.find((i) => i.userId === selectedUser) }]);
            setUsersToSelect([...usersToSelect.filter((i) => i.userId !== selectedUser)]);
            setSelectedUser(null);
        } else {
            notificationError(t('notification.subscribe_to_program'), t('general.fill_fields'));
        }
    };

    const deleteUserFromProgram = async (user) => {
        await WalletService.removeUserFromProgram(selectedProgram.id, { userId: user.userId }).catch((e) => {
            requestError(e, t('notification.unsubscribe_from_program'));
        });
        // compute table
        setProgramUsers([...programUsers.filter((i) => i.userId !== user.userId)]);
        setUsersToSelect([...usersToSelect, user]);
    };

    return (
        <>
            <div className="flex w-full justify-center mb-2">
                <Select
                    className="mr-2"
                    showSearch
                    style={{ width: 300 }}
                    placeholder={t('general.select')}
                    optionFilterProp="children"
                    value={selectedUser}
                    onSelect={(e) => setSelectedUser(e)}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                >
                    {usersToSelect.map((u) => (
                        <Option key={u.userId} value={u.userId}>{`${u.lastName} ${u.firstName}`}</Option>
                    ))}
                </Select>
                <Button type="primary" onClick={addUserToProgram}>
                    {t('general.add')}
                </Button>
            </div>
            <Table dataSource={programUsers} rowKey='userId' loading={loadingUserTable}>
                <Column title={t('user_infos.last_name')} dataIndex="lastName" key="lastName" />
                <Column title={t('user_infos.first_name')} dataIndex="firstName" key="firstName" />

                <Column
                    title={t('general.action')}
                    key="action"
                    render={(record) => (
                        <Space size="middle">
                            <Popconfirm
                                title={t('notification.unsubscribe_from_program_confirmation')}
                                onConfirm={() => deleteUserFromProgram(record)}
                                okText={t('general.yes')}
                                cancelText={t('general.no')}
                            >
                                <Button type="primary">{t('general.delete')}</Button>
                            </Popconfirm>
                        </Space>
                    )}
                />
            </Table>
        </>
    );
};
export default UsersTab;

export const RESOURCE_VIEW_MODE = (i18n) => Object.freeze({
  NOTHING: {label: i18n.t("resource_mode.nothing"), value: 'nothing'},
  NEEDS: {label: i18n.t("resource_mode.needs"), value: 'needs'},
  // RESOURCES: {label: i18n.t("resource_mode.resources"), value: 'resources'},
  // COMPLETE: {label: i18n.t("resource_mode.complete"), value: 'complete'},
})

export const DATE_FORMAT_EN = "YYYY_MM_DD" ;
export const DATE_FORMAT_FR = "DD_MM_YYYY" ;

export default null;
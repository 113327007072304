import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
// import '../../assets/styles/wallet/Types.css'
import { Select } from 'antd';
import { WalletContext } from '../../contexts/wallet/WalletContext';

/* eslint-disable-next-line */
const Types = ({ setActiveType, activeType }) => {
    const { programList } = useContext(WalletContext);

    const { t } = useTranslation();

    const [typeProgram, setTypeProgram] = useState([{ value: 'all', label: t('general.all_types') }]);

    useEffect(() => {
        const secteurs = [];
        programList.forEach((program) => {
            if (secteurs.findIndex((s)=> s.value === program.domainArea) === -1) {
                secteurs.push({
                    value: program.domainArea,
                    label: program.domainArea,
                });
            }
        });
        secteurs.push({ value: 'all', label: t('general.all_types')});
        setTypeProgram(secteurs);
    }, [programList]);

    return (
        <div className="wallet-types">
            <Select
                style={{width: '200px'}}
                options={typeProgram}
                value={activeType}
                onChange={(val) => setActiveType(val)}
                className="w-full"
            />
        </div>
    );
};
export default Types;

export const PARENT_TASK_CONTEXT_MENU = (i18n) => [
  {
    id: 'collapse_level',
    text: i18n.t("general.collapse"),
    items: [
      { id: 'collapse_all', text: i18n.t("general.all") },
      { id: 'collapse_1', text: '1' },
      { id: 'collapse_2', text: '2' },
      { id: 'collapse_3', text: '3' },
      { id: 'collapse_4', text: '4' },
      { id: 'collapse_5', text: '5' },
    ],
  },
  {
      id: 'expand_level',
      text: i18n.t("general.expand"),
      items: [
          { id: 'expand_all', text: i18n.t("general.all") },
          { id: 'expand_1', text: '1' },
          { id: 'expand_2', text: '2' },
          { id: 'expand_3', text: '3' },
          { id: 'expand_4', text: '4' },
          { id: 'expand_5', text: '5' },
      ],
  },
];

export default null;

import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Modal, Tabs } from 'antd';
import Compressor from 'compressorjs';
import UsersTab from './UsersTab';
import VersionTab from './VersionTab';
import { notificationError, requestError } from '../../../helpers/notification';
import WalletService from '../../../services/wallet.service';
import { WalletContext } from '../../../contexts/wallet/WalletContext';

const { TabPane } = Tabs;

const EditProgramModal = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const logoFileInput = React.useRef(null);
    const [isVisible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [defaultTab, setDefaultTab] = useState('programInfo');
    const [logo, setLogo] = useState(null);
    const { selectedProgram, refreshProgramList } = useContext(WalletContext);
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        console.log('on cancel')
        setVisible(false);
        setDefaultTab('programInfo');
    };

    const submitForm = async (values) => {
        if (!selectedProgram) {
            setIsLoading(true);
            WalletService.createProgram({ ...values, logo })
                .then((data) => {
                    setIsLoading(false);
                    refreshProgramList('new', { ...data, logo });
                    setVisible(false);
                })
                .catch((e) => {
                    requestError(e, t('edit_program_modal.program_creation'));
                    setIsLoading(false);
                });
        } else {
            setIsLoading(true);
            WalletService.updateProgram(selectedProgram.id, { ...values })
                .then(async (data) => {
                    if (logo) {
                        await WalletService.updateLogo(selectedProgram.id, { logo });
                    } else {
                        await WalletService.deleteLogo(selectedProgram.id);
                    }
                    refreshProgramList('edit', { ...data, logo });
                    setIsLoading(false);
                    setVisible(false);
                })
                .catch((e) => {
                    requestError(e, t('edit_program_modal.program_modification'));
                    setIsLoading(false);
                });
        }
    };

    const setLogoValue = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            setLogo(reader.result);
        };
        reader.readAsDataURL(file);
    }

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        // if bigger than 2MB block
        if ((file.size / 1024) > 2000) {
            notificationError(t('edit_program_modal.logo_choice'), t('edit_program_modal.file_size_should_not_exceed_2mb'))
            return;
        }
        // if bigger than 500KB compress
        if ((file.size / 1024) > 500) {
            console.log('compressing')
            /* eslint-disable-next-line  */
            new Compressor (file, {
                convertSize: 1000000,
                quality: 0.8,
                success: (compressed) => {
                    setLogoValue(compressed)
                }
            })
        } else {

            setLogoValue(file)
        }
    };

    const handleTabChange = (e) => {
        setDefaultTab(e);
    };

    useEffect(() => {
        if (selectedProgram) {
            form.setFieldsValue(selectedProgram);
            setLogo(selectedProgram.logo);
        } else {
            form.resetFields();
            setLogo(null);
        }
    }, [selectedProgram]);

    return (
        <Modal
            title={`${selectedProgram ? t('edit_program_modal.edition_of_a_program') : t('edit_program_modal.new_program')}`}
            className="activities-grouping-modal"
            centered
            destroyOnClose
            open={isVisible}
            width={1200}
            footer={null}
            onCancel={onCancel}
        >
            <Tabs destroyInactiveTabPane activeKey={defaultTab} onChange={handleTabChange}>
                <TabPane tab={t('edit_program_modal.information')} key="programInfo" className="h-full">
                    <Form form={form} name="grouping_form" layout="vertical" onFinish={submitForm}>
                        <Form.Item label={t('program_info.name')} name="name" rules={[{ required: true, message: t('general.required_field') }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={t('program_info.description')} name="description">
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                            label={t('program_info.company')}
                            name="company"
                            rules={[{ required: true, message: t('general.required_field') }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t('program_info.domain_area')}
                            name="domainArea"
                            rules={[{ required: true, message: t('general.required_field') }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label={t('program_info.url')} name="url" rules={[{ required: true, message: t('general.required_field') }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={t('program_info.logo')}>
                            {logo === null ? (
                                <>
                                    <Button onClick={() => logoFileInput.current.click()}>{t('general.add')}</Button>
                                </>
                            ) : (
                                <>
                                    <img src={logo} alt="logo" width="200" className='mb-2' />
                                    <Button onClick={() => logoFileInput.current.click()} className="mr-2">
                                        {t('general.change')}
                                    </Button>
                                    <Button onClick={() => setLogo(null)}>{t('general.delete')}</Button>
                                </>
                            )}
                            <input
                                type="file"
                                accept="image/png"
                                ref={logoFileInput}
                                style={{ display: 'none' }}
                                onChange={handleLogoChange}
                            />
                        </Form.Item>
                        <div className="flex justify-end">
                            <Button onClick={onCancel} className="mr-2">
                                {t('general.cancel')}
                            </Button>
                            <Button type="primary" htmlType="submit" loading={isLoading}>
                                {t('general.save')}
                            </Button>
                        </div>
                    </Form>
                </TabPane>
                <TabPane tab={t('general.users_plural')} key="programUsers" className="h-full" disabled={selectedProgram === null}>
                    <UsersTab />
                </TabPane>
                <TabPane tab={t('general.version')} key="version" className="h-full" disabled={selectedProgram === null}>
                    <VersionTab />
                </TabPane>
            </Tabs>
        </Modal>
    );
});

export default EditProgramModal;

export const NEED_TYPE = Object.freeze({
  CONSUMMABLE: 'CONSUMABLE',
  NON_CONSUMMABLE: 'NON_CONSUMABLE',
})
export const NEED_VISIBILITY = Object.freeze({
  GLOBAL: 'GLOBAL',
  PLANNING: 'PLANNING',
})
export const NEED_QUANTITY_MODE = Object.freeze({
  FIXE: 'FIXE',
  QUANTITY: 'QUANTITY',
  TEMPORAL: 'TEMPORAL'
})
export const NEED_CHART_TYPE = Object.freeze({
  BAR: 'bar',
  LINE: 'line',
})
export const NEED_CHART_DATA = Object.freeze({
  INSTANTANEOUS: 'instantaneous',
  CUMULATIVE: 'cumulative',
})
export const SLICE_DATA_TYPE = Object.freeze({
  QUANTITY: 'quantity',
  EFFORT: 'effort',
})
export const NEED_HISTOGRAM_TYPE = Object.freeze({
  STACKED: 'stacked',
  UNSTACKED: 'unstacked',
})

export const ALL_GLOBAL_NEEDS = 'Tous les besoins';
export const ALL_PLANNING_NEEDS = 'Tous les besoins du planning';

export default {NEED_TYPE, NEED_VISIBILITY, NEED_QUANTITY_MODE, NEED_CHART_TYPE, NEED_CHART_DATA}